@use "../../utils/variables/variables.scss" as *;

.skiSchool {
  .search {
    margin-top: 60px;
    .table {
      margin-top: 50px;
    }
    .searchFieldFlex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .searchField {
      padding: 0 10px 0 35px;
      position: relative;
      width: 20%;
      & > img {
        position: absolute;
        left: 15px;
        top: 20px;
      }
      .containerDatePicker {
        margin: 0;
      }
    }
    .searchField:after {
      content: "";
      display: block;
      position: absolute;
      height: 45px;
      border: solid 1px #afafaf1f;
      top: -8px;
      right: 0;
    }
    .text {
      text-transform: uppercase;
      color: $white;
      h4 {
        font-family: $DIN-bold;
        font-size: 36px;
        margin: 0;
      }
      h2 {
        font-family: $FBT-heavy;
        font-size: 50px;
        margin: 0;
      }
    }
    .bar {
      background-color: $white;
      border: solid 2px $subColorBlue;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      button.SearchBtn {
        width: 100%;
        padding: 20px;
        font-family: $DIN-bold;
        background: $subColorBlue;
        border: none;
        font-size: 20px;
        color: $white;
        cursor: pointer;
      }
      .select {
        background-color: $white;
      }
      .searchInput {
        // line-height: 30px;
        min-width: 100%;
        & > div {
          z-index: 999;
        }
        > img {
          position: absolute;
          right: 0;
          pointer-events: none;
        }
        & * {
          font-family: $DIN-regular;
          color: #afafaf;
          line-height: 27px;
        }
        svg {
          path {
            color: $mainRed;
          }
        }
        &::before {
          display: none;
        }
      }
    }
  }
  .school {
    background-color: $AnotherSubGray;
    padding-top: 45px;
    padding-bottom: 100px;
    // margin-bottom: 35px;
    .title {
      font-family: "D-DIN-Bold";
      margin: 45px 0 50px 0;
      font-size: 45px;
      line-height: 60px;
      text-align: center;
      color: $subColorBlue;
      margin: 40px auto;
      border-bottom: solid;
      padding-bottom: 20px;
      // text-decoration: underline;
      width: fit-content;
      width: -moz-fit-content;
      position: relative;
      // padding: 5px 25px;
    }
    .title:after {
      content: "";
      background: url(../../img/eclipse.svg);
      position: absolute;
      display: block;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -18px;
      left: calc(50% - 18px);
      background-color: #f8f8f8;
      border-radius: 50%;
    }
    .tableHead {
      & > div:first-child {
        min-height: 72px;
        max-width: 265px;
        background-color: #fcfcfc;
      }
      & > div:nth-child(odd) > div {
        background-color: #fcfcfc;
        padding: 24px 0;
        border-radius: $Radius;
      }
      & > div:nth-child(even) > div {
        background-color: #f5f7fa;
        padding: 24px 0;
        border-radius: $Radius;
      }
      .title {
        font-size: 22px;
        font-family: $DIN-regular;
        color: $mainDarkGray;
        line-height: 24px;
        margin: 0;
        span {
          color: $mainRed;
        }
      }
    }
    .table {
      & > div {
        min-height: 160px;
        & > div {
          min-height: inherit;
        }
      }
      & > div:nth-child(odd) > div {
        // background-color: #FCFCFC;
        border-radius: $Radius;
        // padding: 15px;
      }
      & > div:nth-child(even) > div {
        background-color: #f5f7fa;
        border-radius: $Radius;
        // padding: 15px;
      }
      & > div:nth-child(odd) > div > div .lesson {
        background-color: $white !important;
        padding: 30px;
        margin-bottom: 50px;
      }
      .lesson {
        h4 {
          font-family: $DIN-bold;
          font-size: 22px;
          line-height: 24px;
          margin: 0;
          color: $mainDarkGray;
          text-transform: uppercase;
        }
        p {
          font-family: $DIN-regular;
          font-size: 18px;
          line-height: 20px;
          margin: 10px 0 20px 0;
          color: $mainDarkGray;
        }
        .book {
          color: $white;
          background-color: $mainRed;
          padding: 18px 40px;
          // font-size: 18px;
          //Coming soon
          font-size: 12px;
          // pointer-events: none;
          //Coming soon
          font-family: $DIN-bold;
          text-decoration: none;
          border-radius: $Radius;
          margin-bottom: 8px;
          display: inline-block;
        }
      }
      .price {
        font-family: $FBT-heavy;
        color: $mainDarkGray;
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        span {
          font-size: 20px;
          line-height: 27px;
          color: $mainRed;
        }
      }
    }
    .kids > .head,
    .adults > .head,
    .snowBoarding > .head {
      text-align: center;
      background-color: #f5f7fa;
      margin-bottom: 20px;
      padding: 10px 0;
      p {
        font-size: 14px;
        line-height: 20px;
        font-family: $DIN-regular;
        color: $mainDarkGray;
        margin: 0;
      }
      p:last-child {
        color: $mainRed;
      }
    }
  }
  .note {
    margin: 0px 0 0 0;
    text-align: center;
    p {
      font-family: $DIN-regular;
      font-size: 20px;
      line-height: 22px;
      margin: 12px 0 0 0;
      color: $mainRed;
    }
  }
  .lessonTutorial {
    background-color: $AnotherSubGray;
    padding: 40px 0 70px 0;
    .title {
      font-family: "D-DIN-Bold";
      font-size: 45px;
      line-height: 60px;
      text-align: center;
      color: $subColorBlue;
      border-bottom: solid;
      width: fit-content;
      width: -moz-fit-content;
      margin: 50px auto;
      padding-bottom: 20px;
      // padding: 5px 25px;
      position: relative;
    }
    .title:after {
      content: "";
      background: url(../../img/eclipse.svg);
      position: absolute;
      display: block;
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -18px;
      left: calc(50% - 18px);
      background-color: #fff;
      border-radius: 50%;
    }
  }
  .pro {
    padding: 70px 0 10px 0;
    h4 {
      font-family: "D-DIN-Bold";
      font-size: 26px;
      color: $mainDarkGray;
      line-height: 35px;
      margin: 0;
    }
    h2 {
      font-family: "D-DIN-Bold";
      font-size: 38px;
      color: $subColorBlue;
      line-height: 60px;
      margin: 0 0 15px;
    }
    p {
      font-family: $DIN-regular;
      font-size: 18px;
      color: $mainDarkGray;
      line-height: 24px;
      margin: 0;
    }
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .placeHolder {
      transition: ease all 0.3s;
      object-fit: cover;
      border-radius: $Radius;
    }
    a {
      display: inline-block;
      text-align: center;
      width: 70%;
      background: #4a6ee8;
      border-radius: 5px;
      color: #ffffff;
      text-decoration: none;
      font-family: "D-DIN-Bold";
      padding: 18px 0;
      font-size: 24px;
      margin-top: 5vh;
      border: none;
    }
  }
}
@media (min-width: 1200px) {
  .skiSchool {
    .titleMobile {
      display: none;
    }
  }
}
@media (max-width: 1199px) {
  .skiSchool {
    .search {
      .bar {
        .searchInput {
          min-height: 35px;
          margin-bottom: 10px;
        }
      }
    }
    .school {
      .title {
        // margin: 0px 0 25px 0;
        font-size: 24px;
        line-height: 36px;
      }
      .lesson {
        h4 {
          font-size: 20x !important;
          line-height: 24px !important;
        }
        p {
          font-size: 16px !important;
          line-height: 18px !important;
          margin: 5px 0 20px 0 !important;
        }
        .book {
          padding: 4px 35px !important;
          font-size: 18px !important;
        }
      }
      .price {
        font-size: 24px !important;
        span {
          font-size: 14px !important;
        }
      }
    }
    .lessonTutorial {
      padding: 50px 0 50px 0 !important;
      .title {
        font-size: 24px;
        line-height: 36px;
        // margin-bottom: 0;
      }
    }
  }
}
html[lang="ar"] {
  .searchInput {
    text-align: center;
    & > div {
      font-family: $GE !important;
    }
  }
  .note {
    direction: rtl;
  }
}
