@use "../../../../utils/variables/variables.scss" as vars;
.contactUsContainer {
  .btnContact {
    background-color: vars.$mainRed;
    border: 1px solid vars.$mainRed;
    color: vars.$white;
    font-size: 17px;
    font-family: "Gotham-Bold";
    margin-top: 0px;
    padding: 8px 25px;
    &:hover {
      background-color: vars.$white;
      border: 1px solid vars.$mainRed;
      color: vars.$mainRed;
    }
  }
}
