// ======== Main variables ========
$Radius: 5px;

.cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// ======== Main variables ========
$DIN-italic: 'D-DIN-Italic';
$DIN-condensedBold: 'D-DINCondensed-Bold';
$DIN-expBold: 'D-DINExp-Bold';
$DIN-expItalic: 'D-DINExp-Italic';
$DIN-exp: 'D-DINExp';
$FBT-boldcondensed: 'FuturaBT-BoldCondensed';
$FBT-bolditalic: 'FuturaBT-BoldItalic';
$FBT-book: 'FuturaBT-Book';
$FBT-bookitalic: 'FuturaBT-BookItalic';
$FBT-extrablack: 'FuturaBT-ExtraBlack';
$FBT-heavyitalic: 'FuturaBT-HeavyItalic';
$FBT-lightcondensed: 'FuturaBT-LightCondensed';
$FBT-lightitalic: 'FuturaBT-LightItalic';
$FBT-mediumcondensed: 'FuturaBT-MediumCondensed';
$FBT-mediumitalic: 'FuturaBT-MediumItalic';
$Cairo-bold: 'Cairo-Bold';
$GE: 'GE Dinar One';

//Replaced with Gotham font globally
$FBT-light: 'Gotham-Thin';
$FBT-medium: 'Gotham-Medium';
$FBT-heavy: 'Gotham-Bold';
$DIN-regular: 'Gotham';
$DIN-bold: 'Gotham-Bold';
$GOTH-BOOK: 'Gotham Book';
$D-DIN-BOLD: 'D-DIN-Bold';
$D-DIN-REGULAR: 'D-DIN';
// ======== variables Colors ========
$mainRed: #ff173d;
$mainBlue: #142b73;
$subColorBlue: #4a6ee8;
// $mainRed: #ad0a22;
// $mainBlue: #ad0a22;
// $subColorBlue: #ad0a22;
$mainDarkGray: #404040;
$subGray: #8c8c8c;
$AnotherSubGray: #f8f8f8;
$brown: #31261d;
$white: #ffffff;
$subwhite: #f9f9f9;
// ======== variables Colors ========