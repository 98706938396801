@use "../../../utils/variables/variables.scss" as *;

.crystalPackages {
  .subTitle p {
    font-size: 26px;
    font-family: "Gotham-Bold";
    line-height: 35px;
    color: $mainDarkGray;
    margin-top: 65px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    margin-top: 50px;
  }
  .title p {
    font-size: 40px;
    font-family: "Gotham-Bold";
    line-height: 60px;
    color: $mainRed;
    text-transform: uppercase;
    width: fit-content;
    margin: 15px auto;
    position: relative;
    text-align: center;
    margin-bottom: 50px;
  }
  .benefits {
    background-color: $AnotherSubGray;
    border-radius: $Radius;
    min-height: 180px;
    max-height: 180px;
    position: relative;
    margin-bottom: 30px;
    h6 {
      font-size: 36px;
      font-family: "Gotham-Bold";
      line-height: 26px;
      color: $mainDarkGray;
      position: absolute;
      bottom: 0px;
      left: 30px;
      margin-bottom: 30px;
    }
  }
  .card {
    background-color: $AnotherSubGray;
    border-radius: $Radius;
    margin-bottom: 30px;
    max-height: 119px;
    min-height: 119px;
    word-break: break-word;
    .imgTilte {
      p {
        font-size: 20px;
        font-family: "Gotham-Medium";
        line-height: 26px;
        color: $mainDarkGray;
        padding-left: 16px;
      }
    }
    img {
      height: 100%;
      object-fit: fill;
    }
  }
  .platinumHeader {
    padding: 5px;
    .redColor {
      background-color: $mainRed;
      color: $white;
    }
    .grayColor {
      background-color: $AnotherSubGray;
      color: $mainDarkGray;
    }
    .header {
      min-height: 180px;
      max-height: 180px;
      border-radius: $Radius;
      text-align: center;
      padding: 10px;
      margin-bottom: 30px;
    }
    p {
      margin: 0;
      line-height: 180px;
    }
    .title {
      font-size: 20px;
      font-family: "Gotham-Bold";
      line-height: 26px;
      padding-bottom: 10px;
      span {
        display: block;
      }
    }
    .price {
      font-size: 32px;
      font-family: "Gotham-Bold";
      line-height: 30px;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .red {
      color: $mainRed;
    }
    .btn {
      cursor: pointer;
      border-radius: $Radius;
      font-size: 14px;
      font-family: "Gotham-Bold";
      padding: 10px 45px;
      text-decoration: none;
    }
    .btnGray {
      background-color: $white;
      // pointer-events: none;
      // background: #d0d0d0 !important;
      // color: #7f8186 !important;
      color: $mainRed;
    }
    .btnRed {
      background-color: $mainRed;
      // pointer-events: none;
      // background: #d0d0d0 !important;
      color: $white;
    }
    .cardItem {
      min-height: 119px;
      max-height: 119px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-color: $mainRed;
      margin-bottom: 30px;
      border-radius: $Radius;
      p {
        // color: $white;
        font-size: 20px;
        font-family: "Gotham-Bold";
        margin: 0;
        text-align: center;
      }
    }
  }
  .swiperContainerClass {
    position: unset;
    [class*="showArrows"]:after {
      font-size: 30px;
    }
    [class*="swiper-button-next"] {
      // right: -10px;
      right: 40px;
      visibility: hidden;
    }
    [class*="swiper-button-next active"] {
      visibility: visible;
      position: fixed;
      color: $mainRed;
    }
    [class*="swiper-button-prev"] {
      // left: -10px;
      left: 40px;
      visibility: hidden;
    }
    [class*="swiper-button-prev active"] {
      visibility: visible;
      position: fixed;
      color: $mainRed;
    }
  }
}

html[lang="ar"] {
  .crystalPackages {
    .benefits {
      h6 {
        right: 30px;
      }
    }
    .reverse {
      text-align: right;
      direction: rtl;
      p {
        margin-right: 20px;
      }
    }
    .cardItem {
      direction: rtl;
    }
  }
}
@media screen and (max-width: 768px) {
  .crystalPackages {
    .subTitle p {
      font-size: 20px;
      line-height: 35px;
    }
    .title p {
      font-size: 24px;
      margin-top: 0;
    }
    .benefits {
      h6 {
        font-size: 22px;
        left: 10px;
      }
    }
    .card {
      .imgTilte {
        p {
          font-size: 16px;
          line-height: 18px;
          padding-left: 9px;
        }
      }
      img {
        height: auto;
      }
    }
    .platinumHeader {
      .title {
        font-size: 20px;
      }
      .price {
        font-size: 22px;
      }

      .btn {
        font-size: 14px;
        padding: 8px 35px;
      }

      .cardItem {
        p {
          font-size: 16px;
        }
      }
    }
  }
}
