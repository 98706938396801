@use "../../../../utils/variables/variables.scss" as vars;
.PopUpContainer {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(vars.$color: #000000, vars.$alpha: 0.6);
  z-index: 1000;
  .popupContentContainer {
    background: vars.$white;
    position: absolute;
    width: 63%;
    // height: 500px;
    z-index: 100000;
    top: 50%;
    padding: 10px;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
