@use "../../../../utils//variables/variables.scss" as *;
.ourBestExperiencesContainer {
  padding: 70px 0;
  .packages {
    .title {
      font-size: 26px;
      line-height: 35px;
      margin: 0;
      color: $subColorBlue;
      font-family: "D-DIN-Bold";
      text-align: center;
    }
    .subTitle {
      font-size: 45px;
      line-height: 60px;
      color: $mainBlue;
      font-family: "D-DIN-Bold";
      margin: 0 0 30px;
      text-align: center;
    }
    .packageSlide {
      .cardImgWrap {
        img {
          position: relative;
          width: 100%;
          border-radius: $Radius;
          object-fit: cover;
        }
        h4 {
          font-size: 18px;
          line-height: 24px;
          font-family: $FBT-medium;
          color: $white;
          margin: 0;
          opacity: 1;
          visibility: visible;
          transition: all ease 0.3s;
        }
        h2 {
          font-size: 28px;
          line-height: 37px;
          font-family: $FBT-heavy;
          color: $mainRed;
          margin: 0;
        }
        p {
          font-size: 16px;
          line-height: 20px;
          font-family: $DIN-regular;
          color: $white;
          margin: 0;
        }
        .book {
          // font-size: 18px;
          //Coming soon
          font-size: 12px;
          // pointer-events: none;
          //Coming soon
          font-family: $DIN-bold;
          text-decoration: none;
          color: $white;
          background-color: $mainRed;
          display: inline-block;
          text-align: center;
          width: 100%;
          padding: 12px;
          border-radius: $Radius;
          position: relative;
          z-index: 1;
        }
        .view {
          display: inline-block;
          width: 100%;
          text-align: center;
          margin-top: 15px;
          color: $white;
          font-size: 14px;
          font-family: $DIN-bold;
          position: absolute;
          bottom: -30px;
          left: 20px;
          transition: all ease 0.3s;
        }
        .cardImgContainer {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0 30px;
          margin-bottom: 0;
          & > div:nth-child(2) {
            position: relative;
            z-index: 1;
            bottom: 15px;
          }
        }
      }
    }
    .hotOffers {
      position: absolute;
      z-index: 1;
      width: 60px;
      right: 15px;
      top: 15px;
    }
  }
}
@media (min-width: 1200px) {
  .ourBestExperiencesContainer {
    .packages {
      .packageSlide:hover .view {
        opacity: 1;
        visibility: visible;
        left: 0;
      }
      .packageSlide:hover .cardImgContainer > div:nth-child(2) {
        margin-bottom: 20px;
      }
      .packageSlide:hover .cardImgContainer h4 {
        opacity: 0;
        visibility: hidden;
      }
      .cardImgWrap {
        position: relative;
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: block;
          left: 0;
        }
        .view {
          opacity: 0;
          visibility: hidden;
          transition: ease all 0.3s;
        }
      }
    }
    .packageSlide {
      .cardImgWrap {
        .cardImgContainer {
          position: absolute;
          bottom: 40px !important;
          left: 0;
          padding: 0 30px;
          margin-bottom: 10%;
          & > div:first-child {
            padding-right: 20px;
            padding-left: 20px;
            // margin-bottom: 10px;
            // min-height: 220px;
          }
        }
      }
    }
    .slide .title:hover {
      // transform: scale(1.05);
      margin-left: 5px;
    }
    .offers .slide .book:hover {
      // transform: scale(1.05);
      background-color: $mainRed;
    }
    .slide:hover {
      .more {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
@media (max-width: 1199px) {
  .ourBestExperiencesContainer {
    .packages {
      .title {
        margin-top: 50px;
        font-size: 20px;
      }
      .subTitle {
        font-size: 26px;
        margin: 0 0 15px;
        line-height: 35px;
      }
      .packageSlide {
        .cardImgContainer {
          position: relative !important;
          padding: 0 !important;
          h4 {
            color: $mainDarkGray;
            margin-top: 20px;
          }
          h2 {
            font-size: 22px;
            margin-top: 10px;
          }
          p {
            color: $mainDarkGray;
            margin: 15px 0 40px 0;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  html[lang="ar"] {
    .ourBestExperiencesContainer {
      .packages {
        .cardImgContainer {
          text-align: right;
          > div:first-child {
            direction: rtl;
          }
        }
        > h6,
        h2 {
          text-align: right;
        }
      }
    }
  }
}
