@use "../../../../utils/variables/variables.scss" as vars;
.corporateBenefitsContainer {
  padding-top: 80px;
  h3 {
    color: vars.$mainRed;
    font-size: 28px;
    font-family: "Gotham-Bold";
    text-transform: uppercase;
    margin: 0;
  }
  p {
    font-family: vars.$DIN-regular;
    font-size: 18px;
    line-height: 24px;
    color: vars.$mainDarkGray;
    white-space: break-spaces;
  }
}
