@use "../../../utils/variables/variables.scss" as vars;

.card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    &:hover .image {
        transform: scale(1.1);
    }

    &:hover .hoverContainer {
        max-height: 450px;
        opacity: 1;
    }
}

.imageWrapper {
    position: relative;
    width: 100%;
    height: 450px; // Adjust as needed
    overflow: hidden;

    /* Backdrop styles */
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.6)); // Gradient effect
        // backdrop-filter: blur(10px); // Blur effect
        -webkit-backdrop-filter: blur(10px); // Safari compatibility
        z-index: 1;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
    position: relative;
    z-index: 0; // Ensure the backdrop sits above the image
}

.content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 15px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.3s ease;
    z-index: 2;

    .title {
        margin: 0;
        font-size: 1.4rem;
        font-weight: bold;
        font-family: "Gotham-Bold";
    }

    .hoverContainer {
        opacity: 0; // Initially hidden
        overflow: hidden;
        max-height: 0; // Initially hidden
        transition: all 0.4s ease; // Smooth transition
        display: flex;
        flex-direction: column;
        width: 100%;

        .description {
            margin: 12px 0 12px 0;
            font-size: 1rem;
            font-family: "D-DIN";
        }

        .linkStyle {
            width: 65%;
            text-align: center;
            background: vars.$subColorBlue;
            padding: 10px 20px;
            text-decoration: none;
            color: vars.$white;
            font-size: 16px;
            border-radius: vars.$Radius;
            font-family: "D-DIN-Bold";

        }
    }
}

@media only screen and (max-width: 1200px) {
    .card {
        &:hover .hoverContainer {
            max-height: 300px;
        }
    }

    .imageWrapper {
        height: 300px;
    }

    .content {
        .title {
            font-size: 18px;
        }

        .hoverContainer {
            .description {
                display: none;
            }

            .linkStyle {
                margin: 10px 0px;
                width: 100%;
            }
        }
    }

}

@media only screen and (max-width: 959px) {
    .content {
        .title {
            font-size: 24px;
        }

        .card {
            &:hover .hoverContainer {
                max-height: 350px;
            }
        }

        .imageWrapper {
            height: 350px;
        }

        .hoverContainer {
            opacity: 1;
            max-height: 350px;
        }
    }
}
