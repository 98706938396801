@use '../../../utils/variables/variables.scss' as vars;

.containerHeaderPanel {
  font-family: 'Buttercup' !important;
  font-weight: 400;
  position: relative;

  .bannerContainer {
    width: 100%;
    height: auto;
    position: relative;

    .bannerImage {
      position: absolute;
      width: 100%;
      height: 70vh;
    }

    .bgContainer {
      display: flex;
      justify-content: flex-end;

      .bookContainer {
        z-index: 10;
        margin-top: 110px;
        width: 360px;
        // height: 575px;
        background: linear-gradient(115.53deg, #4A6EE8 13.6%, rgba(46, 70, 152, 0.914688) 60.28%, rgba(0, 12, 51, 0.84) 100.31%);
        border-radius: 19px;
        padding: 50px 30px;

        .title {
          margin: 0;
          font-size: 38px;
          color: #FF173D;
          text-transform: uppercase;
          font-weight: 700;
          font-family: "D-DIN";
          letter-spacing: .3px;
        }

        .price {
          margin: 0;
          margin-top: 5px;
          font-size: 38px;
          font-weight: 700;
          font-family: "D-DIN";
          color: white;
          text-transform: uppercase;
          letter-spacing: .3px;
        }

        .breackLine {
          margin-top: 10px;
          width: 76px;
          height: 10px;
          background: #FF173D;
        }

        .buttonContainer {
          margin-top: 50px;
          display: flex;
          justify-content: center;

          button {
            color: vars.$subColorBlue;
            background: vars.$white;
            display: inline-block;
            text-align: center;
            border-radius: 5px;
            width: 80%;
            height: 51px;
            line-height: 51px;
            font-weight: 700;
            font-size: 20px;
            text-transform: capitalize;
            border: none;
            padding: 0;
            cursor: pointer;
          }
        }

        ul {
          padding: 0px;
          list-style: none;

          li {
            position: relative;
            font-size: 20px;
            font-family: "D-DIN";
            font-weight: 400;
            color: white;
            margin-bottom: 5px;
            line-height: 25.82px;
            padding-left: 28px;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 6px;
              width: 1em;
              height: 1em;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyMCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNzUgOC44NzQyN0w1LjI1IDEzLjg3NDNMMTkuMjUgMC44NzQyNjgiIHN0cm9rZT0iI0YyRjJGMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
            }
          }
        }
      }
    }
  }


  .containerTitel {
    background-color: vars.$subwhite;
    padding: 25px 16px;
    border-radius: 31px;
    -webkit-border-radius: 31px;
    -moz-border-radius: 31px;
    -ms-border-radius: 31px;
    -o-border-radius: 31px;
    box-shadow: 2px 2px 2px #cccccc69;
    position: relative;

    p {
      margin: 0;
    }

    h1 {
      margin: 0;
      font-family: 'Buttercup' !important;
      font-weight: 400;
    }

    .subTitle {
      color: vars.$subColorBlue;
      font-size: 16px;
      font-family: 'Buttercup' !important;
      line-height: 21px;
      font-weight: 400;

    }

    .title {
      color: vars.$mainBlue;
      font-size: 35px;
      font-family: 'Buttercup' !important;
      font-weight: 400;

      line-height: 50px;
    }

    .description {
      color: vars.$mainDarkGray;
      font-size: 15px;
      font-family: vars.$DIN-regular;
      line-height: 18px;
      margin-top: 5px;
    }
  }
}




@media only screen and (max-width: 620px) {
  .containerHeaderPanel {
    .bannerContainer {
      height: auto;

      .bannerImage {
        height: 60vh;
      }

      .bgContainer {
        justify-content: center;

        .bookContainer {
          margin-top: 250px;
          width: 90%;
          padding: 30px 15px;

          .title {
            font-size: 28px;
          }

          .price {
            font-size: 28px;
          }

          .breackLine {
            margin-top: 8px;
            height: 6px;
            width: 56px;
          }


          ul {
            li {
              font-size: 16px;
            }
          }

          .buttonContainer {
            margin-top: 30px;
          }

        }
      }
    }

    .containerTitel {
      margin-top: 40px;
    }

    .containerPanal {
      img {
        // object-fit: cover;
        object-fit: contain;
        height: auto;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .containerHeaderPanel {
    .containerTitel {
      padding: 50px 32px;
      margin-top: 50px;

      .subTitle {
        font-size: 26px;
        line-height: 31px;
      }

      .title {
        font-size: 45px;
        line-height: 60px;
      }

      .description {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}

html[lang='ar'] {
  .containerTitel {
    text-align: right;
  }

  ul {
    li {
      padding-left: 0px;
      padding-right: 28px;

      &::before {
        right: 0;
      }
    }
  }

  .description {
    direction: rtl;
  }
}
