@use '../../../utils/variables/variables.scss' as vars;

.containerVideoSchool {
    .placeHolder{
        width: 100%;
        // max-height: 377px;
        object-fit: cover;
        // border-radius: vars.$Radius;
    }
    .containerData {
        margin-bottom: 35px;
        .containerHeader {
            text-align: center;
            p {
                margin: 0;
                color: vars.$subColorBlue;
                font-size: 25px;
                line-height: 30px;
                font-family: vars.$FBT-heavy;
                border-bottom: solid;
                width: fit-content;
                width: -moz-fit-content;
                margin: 0 auto;
                // padding: 5px 25px;
            }
        }
        .containerDescription {
            font-family: vars.$DIN-regular;
            font-size: 18px;
            color: vars.$mainDarkGray;
            line-height: 26px;
        }
    }
    .containerVideo{
        margin-bottom: 60px;
    }
}
.wrap{
    position: relative;
}
.icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
@media only screen and (min-width: 1280px) {

    .containerVideoSchool {
        .containerData {
            .containerHeader {
                p {
                    color: vars.$subColorBlue;
                    font-size: 44px;
                    line-height: 60px;
                    font-family: vars.$FBT-heavy;
                }
            }
        }
        .containerVideo{
            // max-height: 376px;
            cursor: pointer;
            iframe{
                height: 70vh;
            }
            .placeHolder{
                height: 70vh;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .containerVideoSchool{
        .containerData{
            text-align: center;
            margin-bottom: 0 !important;
        }
        .containerHeader{
            margin-bottom: 20px;
        }
        .containerVideo{
            iframe{
                height: 40vh;
            }
            .placeHolder{
                height: 40vh;
            }
        }
    }
}
