@use "../../../../utils/variables/variables.scss" as vars;
.TabsForGroupPackagesContainer {
  margin: 70px 0;
  .MuiAppBar {
    color: vars.$mainDarkGray;
    background-color: transparent;
    box-shadow: none;
    button {
      background-color: vars.$AnotherSubGray;
      color: vars.$mainDarkGray;
      opacity: 1;
      font-size: 18px;
      font-family: "Gotham-Bold";
      border-right: 1px solid vars.$mainDarkGray;
      text-transform: capitalize;
      &:first-child {
        border-radius: 6px 0 0 0;
      }
      &:last-child {
        border-right: none;
        border-radius: 0 6px 0 0;
      }
    }
    button[aria-selected="true"] {
      background-color: vars.$mainRed;
      color: vars.$white;
    }
  }
}
@media (max-width: 768px) {
  .TabsForGroupPackagesContainer {
    .MuiAppBar {
      button {
        font-size: 16px;
        width: 30%;
      }
    }
  }
}
