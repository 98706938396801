@use "../../utils/variables/variables.scss" as *;
.Note {
  color: $mainRed;
  font-size: 20px;
  line-height: 26px;
  font-family: $DIN-regular;
  text-align: center;
  margin: 35px 0 85px 0;
}
.familyWrap {
  background-color: $AnotherSubGray;
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 20px 0 10px 0;
}
.offerssubZero {
  margin: 70px 0;
  text-align: center;
  h2 {
    font-size: 45px;
    line-height: 60px;
    color: $subColorBlue;
    margin: 0 0 25px 0;
    font-family: "D-DIN-Bold";
  }
  p {
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    color: $mainDarkGray;
    font-family: $DIN-regular;
    white-space: break-spaces;
  }
}
.content {
  margin-bottom: 25px;
  .heading {
    text-align: center;
    margin-bottom: 50px;
    h6 {
      font-size: 26px;
      line-height: 35px;
      color: $subColorBlue;
      font-family: "D-DIN-Bold";
      margin: 0;
      text-transform: uppercase;
    }
    h4 {
      font-size: 40px;
      line-height: 60px;
      color: $mainBlue;
      font-family: "D-DIN-Bold";
      margin: 5px 0 0 0;
      text-transform: uppercase;
    }
  }
  .card {
    position: relative;
    border-radius: 6px;
    * {
      border-radius: 6px;
    }
    > img {
      height: 250px;
      object-fit: cover;
      width: 100%;
    }
    .gradient {
      background: url(../../img/gradient.png);
      min-height: 70px;
      opacity: 0.7;
      background-repeat: no-repeat;
    }
    > div {
      display: flex;
      justify-content: space-between;
      padding: 0 12px;
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 100%;
      // background: linear-gradient(180deg, rgba(255,255,255,0.3) 0%, rgba(74,110,232,0.3) 100%);
      p {
        font-family: $DIN-bold;
        font-size: 20px;
        line-height: 24px;
        color: $white;
      }
      img {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .offerssubZero {
    margin: 20;
  }
  .offerssubZero {
    h2 {
      font-size: 28px !important;
      line-height: 35px !important;
    }
  }
  .heading {
    h4 {
      font-size: 28px !important;
      line-height: 35px !important;
    }
  }
}
html[lang="ar"] {
  .Note {
    direction: rtl;
  }
}
@media only screen and (max-width: 768px) {
  .offerssubZero {
    margin: 0;
  }
}
