@use "../../utils/variables/variables.scss" as *;

footer {
  .firstSection {
    margin-top: 50px;
    margin-bottom: 50px;

    .sectionHeader {
      p {
        font-size: 24px;
        line-height: 32px;
        color: $mainBlue;
        font-family: $FBT-heavy;
        text-transform: uppercase;
        margin: 0;
      }

      span {
        font-family: $DIN-regular;
        font-size: 16px;
        line-height: 25px;
        color: $mainDarkGray;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;

        &:hover {
          color: $mainBlue;
        }
      }
    }

    .sectionEmailAddress {
      display: flex;
      justify-content: center;
      align-items: center;

      form {
        width: 100%;
        font-size: 15px;
        font-family: $DIN-regular;

        input {
          border: 1px solid $mainBlue;
          border-radius: 5px 0 0 5px;
          -webkit-border-radius: 5px 0 0 5px;
          -moz-border-radius: 5px 0 0 5px;
          -ms-border-radius: 5px 0 0 5px;
          -o-border-radius: 5px 0 0 5px;
          // padding: 8px 13px;
          width: 70%;
          color: $mainBlue;
          min-height: 48px;
          max-height: 48px;
        }

        button {
          background: $mainBlue;
          color: $white;
          border: 1px solid $mainBlue;
          border-radius: 0 5px 5px 0;
          -webkit-border-radius: 0 5px 5px 0;
          -moz-border-radius: 0 5px 5px 0;
          -ms-border-radius: 0 5px 5px 0;
          -o-border-radius: 0 5px 5px 0;
          width: 30%;
          // padding: 8px 13px;
          // font-size: 15px;
          // font-family: $DIN-regular;
          // line-height: 18px;
          // line-height: 30px;
          height: 48px;
          cursor: pointer;
        }
      }
    }
  }

  .secondSection {
    .firstColumn {
      p {
        font-family: $DIN-regular;
        font-size: 15px;
        line-height: 24px;
        color: $mainDarkGray;
        text-decoration: none;
        margin: 0 0 20px;
      }

      .sectionSocial {
        & > div {
          margin-right: 0;
        }

        a {
          color: $mainDarkGray;
          font-size: 17px;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;

          &:hover {
            color: $mainBlue;
          }
        }
      }
    }

    .secondColumn {
      a {
        font-family: $DIN-regular;
        font-size: 16px;
        line-height: 26px;
        color: $mainDarkGray;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        width: 100%;
        display: inline-block;

        &:hover {
          color: $mainBlue;
        }
      }
    }

    .sectionHeader {
      font-size: 18px;
      line-height: 20px;
      color: $mainBlue;
      font-family: $FBT-heavy;
      text-transform: uppercase;

      img {
        pointer-events: none;
      }
    }
    background-color: #f6f5f5;
    padding-top: 80px;
    padding-bottom: 80px;

    .sectionData {
      // margin-left: 15px;
      span {
        font-family: $DIN-regular;
        font-size: 16px;
        line-height: 18px;
        text-transform: capitalize;
        color: #404040;
      }

      .spanInfo {
        font-size: 14px;
      }
    }

    .apps {
      margin-top: 30px;
    }
  }

  .containercontainerCopyright {
    background-color: $brown;
    display: flex;
    align-items: center;
    min-height: 60px;
    justify-content: space-between;
    .containerCopyright {
      .sectionBrandName {
        img {
          max-width: 168px;
          height: 34px;
        }
      }

      .sectionBrandTitle {
        span {
          color: $white;
          font-family: $DIN-regular;
          font-size: 14px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .sectionSocial {
    & > div {
      margin-right: 5px !important;
    }
  }

  .sectionData {
    margin-left: 15px !important;
  }
}

html[lang="en"] {
  .sectionHeader {
    img {
      transform: rotate(-90deg);
      margin: 0 0 3px 15px;
    }
  }
}

html[lang="ar"] {
  .sectionHeader {
    img {
      margin: 0 15px 3px 0;
      transform: rotate(90deg);
    }

    p,
    span {
      text-align: right;
      width: 100%;
      display: inline-block;
    }
  }

  .sectionEmailAddress {
    margin-top: 5px;

    input {
      text-align: right;
    }

    button {
      cursor: pointer;
    }
  }

  .firstColumn,
  .fourthCloumn,
  .secondColumn,
  .thirdColumn {
    text-align: right;
    direction: rtl;
  }

  .firstColumn {
    img {
      margin: 0 auto;
    }
  }

  .thirdColumn {
    button {
      width: 100%;
      text-align: right;
    }
  }

  .sectionData {
    text-align: right;
  }

  .sectionData * {
    text-align: right;
    width: 100%;
  }

  .sectionBrandName,
  .sectionBrandTitle {
    text-align: right;
    display: inline-block;
    width: 100%;
  }
}
@media screen and (min-width: 900px) {
  .sectionHeader {
    pointer-events: none;

    p {
      img {
        display: none;
      }
    }
  }
}
