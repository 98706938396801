@use "../../utils/variables/variables.scss" as *;

.home {

  position: relative;

  .search {


    .searchFieldFlex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .searchField {
      padding: 0 10px 0 35px;
      position: relative;
      border: solid 2px $subColorBlue;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding: 18px 10px 18px 35px;
      background: $white;

      &>img {
        position: absolute;
        left: 8px;
        top: 20px;
      }

      .containerDatePicker {
        margin: 0;
      }

      div {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 15px;
          top: 40%;
          cursor: pointer;
          width: 13px;
          height: 8px;
          background-repeat: no-repeat;
          background-size: cover;
          background: url(../../img/blueArrow.png);
        }

        input::placeholder {
          color: #afafaf;
          font-family: "D-DIN";
        }

        input {
          width: 100%;
        }

        ul {
          transform: translate(-37px, 18px);
          width: calc(100% + 49px);
          border: solid 2px #4a6ee8;
          border-top: none;
          border-radius: 0px 0px $Radius $Radius;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;

          li {
            color: #afafaf;
            font-size: 16px;
            font-family: "D-DIN";
            // margin: 18px 0 !important;
          }
        }
      }
    }

    .searchField:after {
      content: "";
      display: block;
      position: absolute;
      height: 45px;
      border: solid 1px #afafaf1f;
      top: -8px;
      right: 0;
    }

    .text {
      text-transform: uppercase;
      color: $white;

      h4 {
        font-family: $DIN-bold;
        font-size: 36px;
        margin: 0;
      }

      h2 {
        font-family: $FBT-heavy;
        font-size: 45px;
        margin: 0;
      }
    }

    .bar {
      // border: solid 2px $subColorBlue;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;

      button.SearchBtn {
        width: 100%;
        font-family: $DIN-bold;
        background: $subColorBlue;
        display: inline-block;
        border: none;
        font-size: 18px;
        line-height: 0px;
        // margin: -1px;
        cursor: pointer;

        a {
          padding: 31px 0;
          text-decoration: none;
          color: #ffffff;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }

      .select {
        background-color: $white;
      }

      .searchInput {
        min-width: 100%;

        & * {
          font-family: $DIN-regular;
          color: #afafaf;
        }

        &>div {
          z-index: 9;
        }

        svg {
          path {
            color: $mainRed;
          }
        }

        &::before {
          display: none;
        }

        img {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .offers {
    .reverse {
      flex-direction: row-reverse;
    }

    .offersWrap {
      &>div {
        padding: 12px 6px;
      }

      .slide:hover .more {
        transform: translateY(0);
      }

      .slide img:hover {
        transform: scale(1.05);
        // transform-origin: top;
      }

      .cardWrapper {
        padding: 0 5px;
      }

      .cardImgWrap {
        overflow: hidden;
        border-radius: $Radius;
        max-width: 280px;

        img {
          border-radius: inherit;
          transition: ease all 0.3s;
        }

        .sking {
          // height: 100%;
          object-fit: cover;
        }
      }
    }

    h6 {
      // color: #6b6666;
      color: $subColorBlue;
      font-size: 26px;
      line-height: 0;
      font-family: "D-DIN-Bold";
      margin: 100px 0 10px;
    }

    h2 {
      color: $mainBlue;
      font-size: 45px;
      // font-size: 78px;
      line-height: 60px;
      font-family: "D-DIN-Bold";
      margin: 20px 0 30px 0;
      // font-family: "Buttercup";
      font-weight: 400;
    }

    .title {
      color: $subColorBlue;
      font-family: $FBT-heavy;
      font-size: 22px;
      line-height: 30px;
      margin: 20px 0 0;
      transition: ease all 0.3s;
    }

    .excerpt {
      font-size: 18px;
      color: $mainDarkGray;
      font-family: $DIN-regular;
      line-height: 24px;
    }

    .book {
      background-color: $subColorBlue;
      font-size: 20px;
      font-family: $DIN-bold;
      color: $white;
      width: 99%;
      display: inline-block;
      text-align: center;
      padding: 14px 0;
      border-radius: $Radius;
      text-decoration: none;
      transition: all ease 0.3s;
      margin-top: 20px;
    }

    .more {
      text-align: center;
      font-family: $DIN-bold;
      font-size: 16px;
      color: $subColorBlue;
      margin-top: 15px;
      display: inline-block;
      width: 100%;
      transition: all ease 0.3s;
    }

    .book:hover {
      text-decoration: none;
    }
  }

  .saveNew {
    padding-top: 100px;
    padding-bottom: 100px;

    .bgImg {
      background-size: cover;
      min-height: 356px;
      background-position: 50%, 50%;
    }

    .buttonContainer {
      display: flex;
      justify-content: center;

      .saveUpPopup {
        margin-top: 25px;
        background-color: #4a6ee8 !important;
        color: #ffffff !important;
        text-decoration: none !important;
        font-family: "D-DIN-Bold" !important;
        font-size: 20px !important;
        line-height: 22px !important;
        width: 20% !important;
        border-radius: 5px !important;
        border-color: transparent !important;
        transition: ease all 0.3s !important;
        display: inline-block !important;
        cursor: pointer;

        a {
          text-transform: uppercase;
          padding: 15px 65px !important;
          color: #ffffff !important;
          text-decoration: none !important;
          font-family: "D-DIN-Bold" !important;
          font-size: 20px !important;
          line-height: 22px !important;
          width: 100%;
          height: 100%;
          display: inline-block;
        }

        &:hover {
          background-color: #ff173d !important;
        }
      }
    }
  }

  .save {
    //background-image: url("../../img/save.jpg");
    background-size: cover;
    min-height: 356px;
    background-position: 50%, 50%;

    .banner {
      background-color: #fcfcfc;

      // visibility: hidden;
      .title {
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        color: $subColorBlue;
        font-family: "D-DIN-Bold";
        text-transform: uppercase;
        text-align: center;
        padding-top: 40px;
      }

      .itemWrap {
        padding-top: 50px;
        padding-bottom: 50px;
        border-radius: $Radius;

        .item {
          text-align: center;
          align-self: baseline;

          h2 {
            line-height: 26px;
            margin: 25px 0 0;
            font-family: $DIN-bold;
            font-size: 20px;
            color: $subColorBlue;
          }
        }
      }
    }

    .offersContainer {
      button {
        a {
          text-transform: uppercase;
        }
      }
    }

    .text {
      .more {
        color: $white;
        text-decoration: none;
        font-family: $DIN-bold;
        font-size: 20px;
        line-height: 22px;
        display: inline-block;
        transform: translateX(-10%);
        transition: ease all 0.2s;
        padding: 15px 80px;
        border-radius: $Radius;
      }

      h6 {
        font-size: 26px;
        line-height: 35px;
        margin: 0;
        font-family: $FBT-medium;
        color: $mainDarkGray;
      }

      h5 {
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        font-family: $FBT-heavy;
        color: $subColorBlue;
      }

      h4 {
        font-size: 45px;
        line-height: 60px;
        margin: 0;
        font-family: $FBT-heavy;
        color: $mainDarkGray;
      }
    }
  }

  .packages {

    // padding:50px;
    .title {
      font-size: 26px;
      line-height: 35px;
      margin: 0;
      color: $mainDarkGray;
      font-family: "D-DIN-Bold";
    }

    .subTitle {
      font-size: 45px;
      line-height: 60px;
      color: $subColorBlue;
      font-family: "D-DIN-Bold";
      margin: 0 0 30px;
    }

    .subTitleNew {
      // font-size: 78px;
      font-size: 45px;
      line-height: 60px;
      color: $mainRed;
      font-family: "D-DIN-Bold";
      // font-family: "Buttercup";
      font-weight: 400;
      margin: 0 0 30px;
      font-weight: 400;

      @media screen and (max-width: 767px) {
        font-size: 52px;
      }
    }

    .packageSlide {
      .cardImgWrap {
        img {
          position: relative;
          width: 100%;
          border-radius: $Radius;
          object-fit: cover;
        }

        h4 {
          font-size: 18px;
          line-height: 24px;
          font-family: $FBT-medium;
          color: $white;
          margin: 0;
          opacity: 1;
          visibility: visible;
          transition: all ease 0.3s;
        }

        h2 {
          font-size: 28px;
          line-height: 37px;
          font-family: $FBT-heavy;
          color: $subColorBlue;
          margin: 0;
        }

        p {
          font-size: 16px;
          line-height: 20px;
          font-family: $DIN-regular;
          color: $white;
          margin: 0;
        }

        .book {
          // font-size: 18px;
          //Coming soon
          font-size: 12px;
          // pointer-events: none;
          //Coming soon
          font-family: $DIN-bold;
          text-decoration: none;
          color: $white;
          background-color: $subColorBlue;
          display: inline-block;
          text-align: center;
          width: 100%;
          padding: 12px;
          border-radius: $Radius;
          position: relative;
          z-index: 1;
        }

        .view {
          display: inline-block;
          width: 100%;
          text-align: center;
          margin-top: 15px;
          color: white;
          font-size: 14px;
          font-family: $DIN-bold;
          // position: absolute;
          // bottom: -30px;
          // left: 5px;
          transition: all ease 0.3s;
        }

        .cardImgContainer {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0 30px;
          margin-bottom: 0;

          &>div:nth-child(2) {
            position: relative;
            z-index: 1;
            bottom: 15px;
          }
        }

        button {
          display: inline-block;
          text-align: center;
          width: 100%;
          background: #4a6ee8;
          border-radius: 5px;
          color: #ffffff;
          text-decoration: none;
          font-family: "D-DIN-Bold";
          // padding: 11px 0;
          // font-size: 20px;
          //Coming soon
          padding: 18px 0;
          font-size: 14px;
          // pointer-events: none;
          //Coming soon
          // margin-top: 8vh;
          margin-top: 5vh;
          border: none;
        }

        button {
          background: #d0d0d0 !important;
          color: #7f8186 !important;
          outline: none;
          border: none;
          pointer-events: none;
        }
      }
    }

    .hotOffers {
      position: absolute;
      z-index: 1;
      width: 60px;
      right: 15px;
      top: 15px;
    }
  }

  .video {
    margin: 95px 0 30px 0;
    padding: 60px 0;

    // background-color: $AnotherSubGray;
    h4 {
      font-family: "D-DIN-Bold";
      font-size: 26px;
      color: $mainDarkGray;
      line-height: 35px;
      margin: 0;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    h2 {
      font-family: "D-DIN-Bold";
      font-size: 45px;
      // font-size: 78px;
      // font-family: "Buttercup";
      font-weight: 400;
      color: $subColorBlue;
      line-height: 60px;
      margin: 0 0 15px;
    }

    p {
      font-family: $DIN-regular;
      font-size: 18px;
      color: $mainDarkGray;
      line-height: 24px;
      margin: 0;
      white-space: break-spaces;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .placeHolder {
      transition: ease all 0.3s;
      object-fit: cover;
      border-radius: $Radius;
    }

    &:hover .placeHolder {
      transform: scale(1.01);
      transform-origin: center;
    }

    iframe {
      border-radius: $Radius;
    }
  }

  .positionRelative {
    position: relative;
  }

  .positionAbsolute {
    position: absolute;
    z-index: 99;
    top: -10px;

    h2 {
      position: absolute;
      color: #a3843b !important;
      left: 50px;
      font-size: 36px;
      font-family: 'Buttercup';
      font-weight: 400;

      span {
        color: #404040 !important;
        font-family: "D-DIN";
        font-size: 22px;
      }

      .goldZodiac {
        font-size: 22px;
        font-family: 'Buttercup';
        font-weight: 400;
        color: #a3843b !important;
      }
    }

    .cmlogo {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;

      img {
        width: 20%;
      }
    }

    .homeHeadImg {
      margin-top: -50px !important;
    }
  }

  @media only screen and (max-width: 1078px) {
    .santaSectionText {
      h1 {
        font-size: 60px;
      }

    }

    .swiperQuery {
      height: 70vh !important;

      img {
        height: 70vh !important;
        object-fit: cover;
      }
    }

    .positionRelative {
      // height: 70vh;
    }

    .positionAbsolute {
      .homeHeadImg {
        margin-top: 0 !important;
        height: 136px;
        object-fit: cover;
      }

      h2 {
        display: none;
      }

      .cmlogo {
        margin-top: 5px;

        img {
          width: 55%;
        }
      }
    }

    .yourExperience {
      padding: 55px !important;
    }

    .santaSectionImage {

      img {
        position: absolute;
        left: -41px !important;
        max-width: 150px !important;
      }
    }

    .santaSectionPackages {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .santaSectionPackagesOffers {
        width: 60% !important;
      }

      .santaSectionPackagesOffersLast {
        width: 60%;
      }
    }
  }

  .positionAbsoluteFoot {
    position: absolute;
    z-index: 99;
    bottom: -5px;
  }
}

@keyframes flash {
  0% {
    background: rgba($color: $white, $alpha: 0.3);
    display: block;
  }

  100% {
    background: transparent;
    display: none;
  }
}

@media (min-width: 1200px) {

  .home {
    .search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
    }

    .textContainer {
      justify-content: center;
    }

    .offers {
      .slide {
        height: 100%;

        >div {
          height: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .more {
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
      }
    }

    .save {
      margin: 255px 0 90px;

      // margin: 100px 0 90px;
      .banner {
        transform: translateY(-50%);
      }

      .text {
        transform: translateY(-50%);

        .more {
          visibility: hidden;
          opacity: 0;
          background-color: $white;
        }
      }
    }

    .save:hover .text .more {
      transform: translateX(0);
      background-color: $subColorBlue;
      visibility: visible;
      opacity: 1;
    }

    .packages {

      // max-width: 100vw;
      // padding-right: 0;
      .packageSlide:hover .view {
        opacity: 1;
        visibility: visible;
        left: 0;
        // margin-bottom: 20px;
      }

      .packageSlide:hover .cardImgContainer>div:nth-child(2) {
        margin-bottom: 20px;
      }

      // .packageSlide:hover .cardImgWrap:after {
      //   animation: flash 0.3s;
      // }
      .packageSlide:hover .cardImgContainer h4 {
        opacity: 0;
        visibility: hidden;
      }

      .cardImgWrap {
        position: relative;

        // img{
        //     min-height: 380px;
        // }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          display: block;
          left: 0;
        }

        .view {
          opacity: 0;
          visibility: hidden;
          transition: ease all 0.3s;
        }
      }
    }

    .packageSlide {
      .cardImgWrap {
        .cardImgContainer {
          position: absolute;
          bottom: 40px !important;
          left: 0;
          padding: 0 30px;
          margin-bottom: 10%;

          &>div:first-child {
            padding-right: 20px;
            padding-left: 20px;
            // margin-bottom: 10px;
            // min-height: 220px;
          }
        }
      }
    }
  }

  .slide .title:hover {
    // transform: scale(1.05);
    margin-left: 5px;
  }

  .offers .slide .book:hover {
    // transform: scale(1.05);
    background-color: $mainRed;
  }

  .slide:hover {
    .more {
      opacity: 1;
      visibility: visible;
    }
  }

  .video {
    .placeHolder {
      min-height: 376px;
    }
  }
}

.search {}

@media (max-width: 1199px) {
  .home {
    .offers {
      h2 {
        font-size: 26px;
      }
    }

    .saveNew {
      .buttonContainer {
        .saveUpPopup {
          width: 60% !important;

          a {
            padding: 10px 0px !important;
          }
        }
      }
    }

    .search {
      position: absolute;
      bottom: 12%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 99;
      padding: 0 15px;

      .textContainer {
        transform: translateY(-50px);

        .text {
          h2 {
            font-size: 45px !important;
            line-height: 50px;
            margin: 10px 0 0;
          }

          h4 {
            font-size: 25px;

            @media screen and (max-width: 767px) {
              font-size: 20px;
            }
          }
        }
      }

      .bar {
        button.SearchBtn {
          // padding: 28px;
          font-size: 16px;
          // margin: 22px !important;
        }
      }
    }

    .offers {
      .more {
        transform: translateY(0);
        margin-bottom: 15px;
        text-align: right;
      }

      h2 {
        text-align: center;
        margin: 10px 0 5px;
        // font-size: 52px;
        line-height: 45px;
        margin-bottom: 25px;

      }

      h6 {
        text-align: center;
        font-size: 20px;
      }

      .cardWrapper {
        .title {
          font-size: 26px;
          margin: 22px 0 0;
        }
      }

      .book {
        padding: 10px 0 !important;
      }
    }

    .save {
      margin-top: 50px;

      .banner {
        .title {
          font-size: 35px !important;
          line-height: 45px !important;
        }
      }

      .itemWrap {
        .item {
          h2 {
            font-size: 20px;
            margin: 5px 0 0;
          }
        }
      }

      .text {
        margin-top: 50px;
        padding-bottom: 50px;

        .more {
          background-color: $subColorBlue;
          transform: translateX(0%);
        }

        h4 {
          font-size: 30px;
          line-height: 50px;
          margin: 0 0 25px;
        }

        h5 {
          font-size: 30px;
          line-height: 34px;
        }
      }
    }

    .packages {
      .title {
        margin-top: 50px;
        font-size: 20px;
      }

      .subTitle {
        font-size: 26px;
        margin: 0 0 15px;
        line-height: 35px;
      }

      .packageSlide {
        .cardImgContainer {
          position: relative !important;
          padding: 0 !important;

          h4 {
            color: $mainDarkGray;
            margin-top: 20px;
          }

          h2 {
            font-size: 22px;
            margin-top: 10px;
          }

          p {
            color: $mainDarkGray;
            margin: 15px 0 40px 0;
          }
        }
      }
    }

    .video {

      margin-top: 20px !important;
    }
  }
}

@media (max-width: 767px) {


  html[lang="ar"] {
    .searchField {
      >img {
        right: 10px !important;
      }
    }
  }

  .searchField {
    div {
      ul {
        transform: translate(-37px, 16px) !important;
      }
    }

    >img {
      top: 17px !important;
    }
  }

  .offers {
    .offersWrap {
      .cardImgWrap {
        max-width: 100% !important;

        img {
          width: 100%;
        }
      }
    }
  }

  .search {
    .bar {
      .searchInput {
        min-height: 40px;

        &>div {
          z-index: 9;
        }

        svg {
          transform: translateX(5px);
        }
      }
    }
  }

  .save {
    // background-image: url("../../img/saveMobile.png") !important;
    // background-size: contain !important;
    margin-top: 50px;

    .item {
      margin-top: 25px;

      img {
        height: 60px;
      }

      .goggles {
        height: 40px;
      }
    }
  }

  .saveUp {
    opacity: 1 !important;
    visibility: visible !important;
  }

  html[lang="ar"] {
    .search {
      .textContainer {
        display: flex;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
}

@media (min-width: 768px) {
  html[lang="ar"] {
    .searchField {
      >img {
        right: auto !important;
        top: 25px !important;
      }
    }
  }

  .home {
    .search {
      .textContainer {
        justify-content: center;
        margin-bottom: 25px;

        .text {
          text-align: center;
        }
      }
    }

    .searchField {
      >img {
        left: 10px !important;
        top: 20px !important;
      }

      padding: 20px 10px 20px 35px !important;

      input {
        font-size: 16px !important;
        color: #afafaf;
        padding-left: 10px;
      }

      input:placeholder {
        color: #afafaf;
      }
    }
  }
}

html[lang="ar"] {
  .searchInput>div {
    font-family: $GE !important;
  }

  .searchField {
    .searchInput {
      text-align: right;

      img {
        right: auto;
        left: 0 !important;
      }
    }

    div {
      input {
        text-align: right;
        padding-right: 30px;
      }

      input::placeholder {
        font-family: $GE !important;
      }

      ul {
        li {
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }

  .searchField div::after {
    right: 10px !important;
  }

  .offers {
    text-align: right;

    .excerpt {
      direction: rtl;
    }
  }

  .save {
    .text {

      h6,
      h5,
      h4 {
        text-align: right;
      }
    }
  }

  .packages {
    .cardImgContainer {
      text-align: right;

      >div:first-child {
        direction: rtl;
      }
    }

    >h6,
    h2 {
      text-align: right;
    }
  }

  .video {
    text-align: right;
    direction: rtl;
  }

  .slide .title:hover {
    // transform: scale(1.05);
    margin-left: 0 !important;
    margin-right: 5px;
  }

  .search {
    .bar {
      .SearchBtn {
        line-height: 10px !important;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
}

// @media (max-width: 769px) {
//   .save {
//     background-image: url("../../img/saveMobile.png") !important;
//     background-size: contain !important;
//   }
// }
.yourExperience {
  // padding: 160px;
  // background-image: url('../../img/experiencebg.png');
}

.positionAbsoluteExp {
  position: absolute;
  z-index: 99;
  top: -10px;
  left: 0;
}

.positionAbsoluteFootExp {
  position: absolute;
  z-index: 99;
  left: 0;
  bottom: -5px;
}
