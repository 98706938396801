@use "../../../../utils/variables/variables.scss" as *;

.registerationPackageContainer {
  .registerationBtn {
    color: $white;
    background: $subColorBlue;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    width: 63%;
    height: 51px;
    line-height: 51px;
    text-transform: uppercase;
    border: none;
    padding: 0;

    &:hover {
      background: $subColorBlue;
      color: $white;
      border: none;
    }

    span {
      font-family: $FBT-heavy !important;
      font-size: 18px !important;
    }
  }

  .registerationWhiteBtn {
    color: $mainBlue;
    background: $white;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    width: 63%;
    height: 51px;
    line-height: 51px;
    text-transform: uppercase;
    border: none;
    padding: 0;

    &:hover {
      background: $subColorBlue;
      color: $white;
      border: none;
    }

    span {
      font-family: $FBT-heavy !important;
      font-size: 18px !important;
    }
  }
}