@use "../../../utils/variables/variables.scss" as *;

.trainers {
  // background-color: $AnotherSubGray;
  padding-top: 45px;
  padding-bottom: 70px;
  .penguinSlide {
    img {
      border-radius: $Radius;
      max-height: 300px;
    }
    h5 {
      color: $subColorBlue;
    }
    .sliderItem {
      color: $subColorBlue;
    }
  }
}
.meet {
  h4 {
    font-size: 40px;
    line-height: 55px;
    font-family: "D-DIN-Bold";
    text-transform: uppercase;
    color: $subColorBlue;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid;
    width: fit-content;
    width: -moz-fit-content;
    padding-bottom: 20px;
    // text-decoration: underline;
    // padding: 5px 25px;
    position: relative;
  }
  h4:after {
    content: "";
    background: url(../../../img/eclipse.svg);
    position: absolute;
    display: block;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -18px;
    left: calc(50% - 18px);
    background-color: #f8f8f8;
    border-radius: 50%;
  }
}
.penguinSlide h5 {
  font-size: 24px;
  line-height: 35px;
  font-family: $DIN-regular;
  color: $subColorBlue;
  margin: 25px 0 10px 0;
  text-align: center;
}
.sliderItem {
  font-size: 18px;
  line-height: 24px;
  font-family: $FBT-heavy;
  color: $subColorBlue;
  margin: 10px 0;
  text-align: center;
  padding: 0 10px;
  span {
    color: $mainDarkGray;
  }
}
@media screen and (max-width: 768px) {
  .meet {
    h4 {
      font-size: 24px !important;
      line-height: 36px !important;
    }
  }
  .trainers {
    .penguinSlide {
      img {
        max-height: none !important;
      }
    }
  }
}
